/* Pricing Section Styles */
.pricing-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa; /* Light background */
  }
  
  .pricing-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .pricing-table {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 1rem;
    max-width: 300px;
    text-align: center;
  }
  
  .pricing-plan {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .pricing-price {
    font-size: 1.5rem;
    color: #007bff; /* Highlight color */
    margin-bottom: 1.5rem;
  }
  
  .pricing-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1.5rem;
  }
  
  .pricing-features li {
    margin-bottom: 0.5rem;
  }
  
  .cta-button {
    background-color: #ffca28;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ffb300;
  }
  
  .pricing-discounts {
    text-align: center;
    margin-top: 2rem;
  }
  
  .pricing-discounts p {
    font-size: 1.2rem;
    color: #333;
  }
  