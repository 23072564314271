/* Style for the About section */
.about-section {
    padding: 4rem 2rem;
    background-color: #f9f9f9; /* Slightly off-white background for better contrast */
    border-top: 5px solid #007bff; /* Add a top border for visual interest */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.about-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #333; /* Dark text color */
    position: relative;
    display: inline-block;
}

.about-title::after {
    content: "";
    display: block;
    height: 4px;
    width: 80px;
    background-color: #007bff;
    margin: 1rem auto 0;
}

.about-description {
    font-size: 1.25rem;
    color: #555; /* Slightly lighter text color */
    line-height: 1.8;
    margin-bottom: 2rem;
    max-width: 800px; /* Limit width for better readability */
    margin: 0 auto; /* Center the text block */
}

/* Optional: Add a section for a profile picture or additional content */
.about-image {
    margin-top: 2rem;
}

.about-image img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Responsive design adjustments */
@media (min-width: 768px) {
    .about-section {
        padding: 4rem 5rem;
    }

    .about-description {
        font-size: 1.5rem;
        line-height: 1.8;
    }
}
