.template-details {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .template-demo img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .template-info {
    margin-top: 2rem;
  }
  
  .template-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .template-features,
  .template-customization,
  .template-testimonials {
    list-style-type: none;
    padding: 0;
  }
  
  .template-features li,
  .template-customization li,
  .template-testimonials li {
    margin-bottom: 0.5rem;
  }
  
  .template-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
    margin: 1rem 0;
  }
  
  .cta-button {
    background-color: #ffca28;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 1.25rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ffb300;
  }
  