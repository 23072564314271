/* About Us & Why Choose Us Section Styles */
.about-us-section {
    padding: 4rem 2rem;
    background-color: #fff; /* White background */
  }
  
  .about-title, .why-title, .testimonials-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .about-description {
    font-size: 1.25rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .why-choose-us {
    margin-bottom: 2rem;
    text-align: center; /* Ensure text is centered */
  }
  
  .why-list {
    list-style: none;
    padding: 0;
    font-size: 1.1rem;
    color: #555;
    margin: 0 auto;
  }
  
  .why-list li {
    margin: 0.5rem 0;
  }
  
  .testimonials {
    margin-top: 2rem;
  }
  
  .testimonials-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .testimonial-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    text-align: center;
  }
  
  .testimonial-feedback {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .testimonial-name {
    font-size: 1rem;
    color: #666;
  }
  