/* HeroSection.css */
body {
  margin: 0;
  padding: 0;
}

.hero-section {
    background: linear-gradient(90deg, #007bff, #6610f2); /* Gradient background */
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  
  .hero-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
  }
  
  .hero-content {
    color: white;
    margin-bottom: 2rem;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .hero-cta {
    background-color: #ffca28;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 1.25rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero-cta:hover {
    background-color: #ffb300;
  }
  
  .hero-image img {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  @media (min-width: 768px) {
    .hero-container {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .hero-content {
      text-align: left;
      max-width: 50%;
    }
  
    .hero-image {
      max-width: 50%;
    }
  }
  