/* Contact Section Styles */
.contact-section {
    padding: 4rem 2rem;
    background-color: #f4f4f4;
  }
  
  .contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .contact-description {
    font-size: 1.25rem;
    color: #666;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .contact-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
  
  .contact-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 1.25rem;
    padding: 1rem;
    border-radius: 8px;
    width: 250px;
    justify-content: center;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .contact-link.instagram {
    background-color: #e4405f;
  }
  
  .contact-link.twitter {
    background-color: #1877f2;
  }
  
  .contact-link.linkedin {
    background-color: #0077b5;
  }
  
  .contact-link.facebook {
    background-color: #1877f2;
  }
  
  .contact-link:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .contact-icon {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
  }
  